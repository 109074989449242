import React, { Component } from "react";
import { db } from "../Firebase/firebase";
import ReserveData from "./ReserveData";
import FilterResults from "react-filter-search";
import { AppContext } from "../context/AppContext";
import moment from "moment";
import RequestCard from "./RequestCard";
import { message } from "flwww";
//import "rc-datepicker/lib/style.css";

export default class Dashboard extends Component {
  static contextType = AppContext;
  state = {
    requests: [],
    loading: true,
    date: "",
    count: 0,
    dates: [],
    sort: true,
    searchValue: "",
    onlyUnaddressed: false,
    drawerIsVisible: false,
  };
  componentDidMount() {
    db.collection("requests")
      .where("confirmed", "==", false)
      .onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          let info = doc.data();
          data.push({ ...info, id: doc.id });
        });
        this.setState({
          requests: data.sort((a, b) => {
            return a.createdAt - b.createdAt;
          }),
          /* .filter((item) => !item.confirmed), */
        });
      });
  }

  sortTime = (dataArray) => {
    let timeAdded = dataArray.map((data) => {
      let date = data.date.toDate();
      let timestring = data.time;
      timestring = timestring.split(":");
      let hour = parseInt(timestring[0]);
      let minute = parseInt(timestring[1]);
      let timeInt = hour * 100 + minute;
      date.setHours(hour, minute);
      let expiry = new Date(date.getTime() + 10 * 60000);
      data.timeNumber = timeInt;
      data.dateTime = expiry;
      return data;
    });

    let timeSorted = timeAdded.sort((a, b) => {
      return a.timeNumber - b.timeNumber;
    });

    return timeSorted;
  };

  toggleStatus = (reqId, status) => {
    db.collection("requests").doc(reqId).update({
      confirmed: !status,
    });
  };

  addCount = () => {
    let current = this.state.count;
    this.setState({
      count: current + 1,
    });
  };

  changeSort = (sort) => {
    this.setState({ sort });
  };
  /* {!this.state.loading
          ? this.state.reservations.map(each => {
              if (this.state.date == "") {
                return <ReserveData key={each.id} data={each}></ReserveData>;
              } else {
                let reserveDate = `${each.date
                  .toDate()
                  .getFullYear()}-${each.date
                  .toDate()
                  .getMonth()}-${each.date.toDate().getDate()}`;
                if (reserveDate === calDate) {
                  return <ReserveData key={each.id} data={each}></ReserveData>;
                } else {
                  console.log("fail");
                  return null;
                }
              }
            })
          : null} */
  render() {
    return (
      <ul className="dashboard">
        {this.state.requests.map((request) => {
          return (
            <RequestCard
              key={`request-${request.song}-${request.name}`}
              request={request}></RequestCard>
          );
        })}
      </ul>
    );
  }
}

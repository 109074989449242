import React, { Component } from "react";
import moment from "moment";
import { db } from "../Firebase/firebase";

export default class RequestCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      left: 0,
      originalOffset: 0,
      velocity: 0,
      timeOfLastDragEvent: 0,
      touchStartX: 0,
      prevTouchX: 0,
      beingTouched: false,
      height: 0,
      clearOpacity: 0,
      intervalId: null,
    };
  }

  componentDidMount() {
    window.setTimeout(() => this.setState({ height: 160 }), 50);
  }

  animateSlidingToZero() {
    let { left, velocity, beingTouched } = this.state;
    if (!beingTouched && left < -0.01) {
      velocity += 10 * 0.033;
      left += velocity;
      if (left < -350) {
        window.clearInterval(this.state.intervalId);
        this.handleRemoveSelf();
      }
      this.setState({ left, velocity });
    } else if (!beingTouched) {
      left = 0;
      velocity = 0;
      window.clearInterval(this.state.intervalId);
      this.setState({ left, velocity, intervalId: null, originalOffset: 0 });
    }
  }

  onRemoval = () => {
    db.collection("requests").doc(this.props.request.id).update({
      confirmed: true,
    });
  };

  handleRemoveSelf() {
    this.setState({ height: 0 });
    window.setTimeout(() => this.onRemoval(), 250);
  }

  handleStart(clientX) {
    if (this.state.intervalId !== null) {
      window.clearInterval(this.state.intervalId);
    }
    this.setState({
      originalOffset: this.state.left,
      velocity: 0,
      timeOfLastDragEvent: Date.now(),
      touchStartX: clientX,
      beingTouched: true,
      intervalId: null,
    });
  }

  handleMove(clientX) {
    if (this.state.beingTouched) {
      const touchX = clientX;
      const currTime = Date.now();
      const elapsed = currTime - this.state.timeOfLastDragEvent;
      const velocity = (20 * (touchX - this.state.prevTouchX)) / elapsed;
      let deltaX = touchX - this.state.touchStartX + this.state.originalOffset;
      let opacityX = (deltaX * -1) / 100;
      console.log(opacityX);
      if (deltaX < -350) {
        this.handleRemoveSelf();
      } else if (deltaX > 0) {
        deltaX = 0;
      }
      this.setState({
        left: deltaX,
        velocity,
        timeOfLastDragEvent: currTime,
        prevTouchX: touchX,
        clearOpacity: opacityX,
      });
    }
  }

  handleEnd() {
    this.setState({
      velocity: this.state.velocity,
      touchStartX: 0,
      beingTouched: false,
      intervalId: window.setInterval(this.animateSlidingToZero.bind(this), 33),
    });
  }

  handleTouchStart(touchStartEvent) {
    touchStartEvent.preventDefault();
    this.handleStart(touchStartEvent.targetTouches[0].clientX);
  }

  handleTouchMove(touchMoveEvent) {
    this.handleMove(touchMoveEvent.targetTouches[0].clientX);
  }

  handleTouchEnd() {
    this.handleEnd();
  }

  handleMouseDown(mouseDownEvent) {
    mouseDownEvent.preventDefault();
    this.handleStart(mouseDownEvent.clientX);
  }

  handleMouseMove(mouseMoveEvent) {
    this.handleMove(mouseMoveEvent.clientX);
  }

  handleMouseUp() {
    this.handleEnd();
  }

  handleMouseLeave() {
    this.handleMouseUp();
  }

  render() {
    let { request } = this.props;
    return (
      <li
        className="swipeItem"
        style={{
          height: this.state.height + "px",
          transition: "height 250ms ease-in-out",
        }}
        onTouchStart={(touchStartEvent) =>
          this.handleTouchStart(touchStartEvent)
        }
        onTouchMove={(touchMoveEvent) => this.handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => this.handleTouchEnd()}
        // The following event handlers are for mouse compatibility:
        onMouseDown={(mouseDownEvent) => this.handleMouseDown(mouseDownEvent)}
        onMouseMove={(mouseMoveEvent) => this.handleMouseMove(mouseMoveEvent)}
        onMouseUp={() => this.handleMouseUp()}
        onMouseLeave={() => this.handleMouseLeave()}>
        <div
          className="swipeItem-content"
          style={{ left: this.state.left + "px" }}>
          <div
            key={request.id}
            className="request-box"
            onClick={() => this.toggleStatus(request.id, request.confirmed)}
            style={
              request.confirmed
                ? { backgroundColor: "green", color: "white" }
                : {}
            }>
            <div
              className="clear-ind"
              style={{ opacity: this.state.clearOpacity }}>
              <h3>CLEAR</h3>
            </div>
            <div className="song-dets">
              <h4 className="artist">{request.artist}</h4>
              <h4>{request.song}</h4>
            </div>
            <hr />
            <div className="name-date">
              <h4 className="req-name">{request.name}</h4>
              <h4 className="created">
                {moment(request.createdAt.toDate()).format("HH:mm")}
              </h4>
            </div>
            {request.dedication !== "" ? <hr /> : null}
            <div className="dedications">{request.dedication}</div>
          </div>
        </div>
      </li>
    );
  }
}

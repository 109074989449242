import React, { Component } from "react";

export default class Confirmation extends Component {
  render() {
    return (
      <div className="confirmation-box">
        <img id="inverse-logo" src={require(`../../images/CM-PB.png`)}></img>
        <div className="confirmation-text">
          <h1>Song request in the queue. Enjoy the music!</h1>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import Dashboard from "./dashboard/Dashboard";

import History from "./dashboard/History";
import Edit from "./create/Edit";
import { Route } from "react-router-dom";
import { NavBar } from "./NavBar";

import { AppContext } from "./context/AppContext";
import { Helmet } from "react-helmet";

import { Drawer } from "flwww";

export default class Outlet extends Component {
  static contextType = AppContext;
  render() {
    let { outlet } = this.context;
    return (
      <div className="outlet">
        <Helmet>
          <title>{this.context.outlet}</title>
        </Helmet>
        {outlet === "TCS" ? (
          <img
            className="outlet-logo"
            src={require(`../images/${outlet}.png`)}></img>
        ) : (
          <img id="inverse-logo" src={require(`../images/${outlet}.png`)}></img>
        )}
        <span id="version">4.0.1b</span>
        <Route
          path={`/main/dashboard`}
          render={(props) => (
            <Dashboard {...props} setCount={() => this.setCount} />
          )}
        />
        <Route
          path={`/main/edit/:reserve`}
          render={(props) => <Edit {...props} />}
        />
        <Route
          path={`/main/history`}
          render={(props) => <History {...props} />}
        />
      </div>
    );
  }
}
